import { useCallback } from "react";

import { email } from "@gility/db/zod/authInputs";
import { gilityEnv } from "@gility/lib";
import { Button, InputField } from "@gility/ui";

import { StepActionsContainer, StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthAction, AuthStep } from "./types";

export const EmailPhoneStep = () => {
  const store = useAuthStore();
  const form = store.form?.current;
  if (!form) {
    return null;
  }

  return (
    <StepContainer step={AuthStep.emailOrPhone}>
      <InputField
        label="Inserisci la tua e-mail o il tuo numero di telefono"
        placeholder="E-mail"
        type="email"
        {...form.register("emailOrPhone")}
      />
    </StepContainer>
  );
};

export const EmailPhoneStepActions = () => {
  const store = useAuthStore();
  const form = store.form?.current;

  const validateInput = useCallback(() => {
    if (!form) {
      return null;
    }

    const parsed = email.safeParse(store.email);
    if (parsed.error) {
      return form.setError("emailOrPhone", { type: "invalid", message: "E-mail non valida" });
    }

    store.setEmail(parsed.data);

    // Logica di switch tra email e telefono
    if (gilityEnv.dev() && store.email?.includes("phone-test-dev")) {
      store.sendAction(AuthAction.phone);
    } else {
      store.sendAction(AuthAction.email);
    }
  }, [store, form]);

  if (!form) {
    return null;
  }

  return (
    <StepActionsContainer step={AuthStep.emailOrPhone}>
      <div>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            validateInput();
          }}
          loading={store.isLoggingIn}
          data-testid="next-button">
          Avanti
        </Button>
      </div>
      <Button
        onClick={() => {
          store.sendAction(AuthAction.invite);
        }}
        variant="link"
        color="primary">
        Hai ricevuto un codice invito?
      </Button>
    </StepActionsContainer>
  );
};
