import { z } from "zod";

const password = z.string().min(8, "La password deve essere lunga almeno 8 caratteri");
export const email = z.string().email({ message: "E-mail non valida" });
export const phone = z
  .string()
  .regex(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
  .min(8)
  .max(11);

const passwordMatchRefine = {
  func: (data: z.infer<typeof newPassword>) => data.newPassword === data.newPasswordConfirmation,
  message: {
    message: "Le password non coincidono",
    path: ["newPasswordConfirmation"],
  },
};

const newPassword = z.object({
  newPassword: password,
  newPasswordConfirmation: password,
});

export const newPasswordInput = newPassword.refine(
  passwordMatchRefine.func,
  passwordMatchRefine.message,
);

const changePassword = newPassword.extend({
  currentPassword: z.string().min(1),
});

export const changePasswordInput = changePassword.refine(
  passwordMatchRefine.func,
  passwordMatchRefine.message,
);

export const loginInput = z.object({
  email: z.string().min(1, "E-mail non valida"),
  emailOrPhone: z.string().min(1, "E-mail o numero di telefono obbligatorio"),
  password: z.string().min(1, "La password è obbligatoria"),
});

export type LoginInput = z.infer<typeof loginInput>;

export const signupInput = z
  .object({
    email: email,
    newPassword: password,
    newPasswordConfirmation: password,
    firstName: z.string().min(1, "Il nome è obbligatorio"),
    lastName: z.string().min(1, "Il cognome è obbligatorio"),
    privacy: z.boolean().refine((data) => data === true, "Devi accettare la Privacy policy"),
    marketing: z.boolean(),
  })
  .refine(passwordMatchRefine.func, passwordMatchRefine.message);

export type SignupInput = z.infer<typeof signupInput>;
