import { useCallback } from "react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import { Button, PasswordField, showToast } from "@gility/ui";

import type { AuthStepActionsProps } from "./types";
import { StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthStep } from "./types";

export const PasswordStep = () => {
  const store = useAuthStore();
  const form = store.form?.current;
  if (!form || !store.isReady) {
    return null;
  }

  return (
    <StepContainer step={AuthStep.password}>
      <PasswordField
        label="Password"
        placeholder="Password"
        autoComplete="current-password"
        autoCapitalize="false"
        autoCorrect="false"
        spellCheck="false"
        {...form.register("password")}
      />
      <div>
        <Button
          href={`/auth/reset${store.email ? `?email=${store.email}` : ""}`}
          variant="link"
          color="gray"
          className="mt-2"
          data-testid="reset-password-button">
          Ho dimenticato la password
        </Button>
      </div>
    </StepContainer>
  );
};

export const PasswordStepActions = (_props: AuthStepActionsProps) => {
  const store = useAuthStore();
  const router = useRouter();

  const form = store.form?.current;

  const passwordLogin = useCallback(async () => {
    if (!form) {
      return;
    }

    store.setIsLoggingIn(true);

    await signIn("credentials", {
      email: store.email,
      password: form.getValues("password"),
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        const callbackUrl = router.query.callbackUrl;
        void router.replace(
          callbackUrl && callbackUrl !== "/" ? callbackUrl.toString() : "/dashboard",
        );
      } else {
        if (res?.error) {
          showToast(
            "Errore durante l'autenticazione. Per favore ricontrolla i dati inseriti.",
            "error",
          );
        }
        store.setIsLoggingIn(false);
      }
    });
  }, [store, form, router]);

  return (
    <div className="mt-6 flex w-full flex-col gap-2 md:flex-row md:items-center md:gap-8">
      <div>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            void passwordLogin();
          }}
          loading={store.isLoggingIn}
          data-testid="next-button">
          Accedi
        </Button>
      </div>
    </div>
  );
};
