import React, { useState } from "react";

import { cn } from "@gility/lib";
import { Label } from "@gility/ui";

export type OTPInputProps = {
  length: number;
  title?: string;
  onChange: (value: string) => void;
  upcaseOnly?: boolean;
  className?: string;
};

const OTPInput = ({ length, title, onChange, upcaseOnly, className }: OTPInputProps) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const [error, setError] = useState(false);

  const handleChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    onChange(newValues.join(""));

    // Move to the next input field if a value is entered
    if (value && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    const pasteValues = paste.split("").slice(0, length);
    const processedValues = upcaseOnly
      ? pasteValues.map((value) => value.toLocaleUpperCase())
      : pasteValues;
    setValues(processedValues);
    onChange(processedValues.join(""));

    // Move focus to the next input field if pasting fills multiple inputs
    const nextIndex = processedValues.length - 1;
    if (nextIndex < length) {
      document.getElementById(`otp-input-${nextIndex}`)?.focus();
    }
  };

  // Delete function will return to the previous input field
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && index > 0) {
      e.preventDefault();

      const newValues = [...values];
      if (newValues[index] === "" && index > 0) {
        document.getElementById(`otp-input-${index - 1}`)?.focus();
      } else {
        newValues[index] = "";
        setValues(newValues);
      }
    }
  };

  const handleFocusOut = () => {
    setTimeout(() => {
      const activeElement = document.activeElement;
      const isOtpInput = activeElement?.id.startsWith("otp-input-");

      if (!isOtpInput) {
        setError(values.some((value) => !value));
      }
    }, 10);
  };

  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <Label>{title ?? "Codice di verifica"}</Label>
      <div onPaste={handlePaste} className="flex gap-4">
        {Array.from({ length: length }).map((_, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength={1}
            value={values[index]}
            onChange={(e) =>
              handleChange(upcaseOnly ? e.target.value.toLocaleUpperCase() : e.target.value, index)
            }
            onKeyDown={(e) => handleKeyDown(e, index)}
            onBlur={handleFocusOut}
            className="w-10 border-0 border-b border-gray-300 text-center"
          />
        ))}
      </div>
      {error && <small className="text-red-600">Codice non valido</small>}
    </div>
  );
};

export default OTPInput;
