import { type UseFormRegister } from "react-hook-form";

export enum AuthStep {
  email = "email",
  emailOrPhone = "emailOrPhone",
  password = "password",
  emailPassword = "emailPassword",
  inviteCode = "inviteCode",
  smsOtp = "smsOtp",
}

export enum AuthAction {
  next = "next",
  back = "back",
  invite = "invite",
  email = "email",
  phone = "phone",
}

export type AuthStepsConfig = Partial<Record<AuthStep, Partial<Record<AuthAction, AuthStep>>>>;
export type AuthConfig = {
  allSteps: AuthStep[];
  steps: AuthStepsConfig;
};

export const twoStepEmailPhonePasswordConfig: AuthConfig = {
  allSteps: [AuthStep.emailOrPhone, AuthStep.password, AuthStep.smsOtp, AuthStep.inviteCode],
  steps: {
    emailOrPhone: {
      email: AuthStep.password,
      phone: AuthStep.smsOtp,
      invite: AuthStep.inviteCode,
    },
    password: {
      back: AuthStep.emailOrPhone,
    },
    smsOtp: {
      back: AuthStep.emailOrPhone,
    },
    inviteCode: {
      back: AuthStep.emailOrPhone,
    },
  },
};

export const twoStepEmailPasswordConfig: AuthConfig = {
  allSteps: [AuthStep.email, AuthStep.password, AuthStep.inviteCode],
  steps: {
    email: {
      email: AuthStep.password,
      invite: AuthStep.inviteCode,
    },
    password: {
      back: AuthStep.email,
    },
    inviteCode: {
      back: AuthStep.email,
    },
  },
};

export const singleStepEmailPasswordConfig: AuthConfig = {
  allSteps: [AuthStep.emailPassword, AuthStep.inviteCode],
  steps: {
    emailPassword: {
      invite: AuthStep.inviteCode,
    },
    inviteCode: {
      back: AuthStep.emailPassword,
    },
  },
};

export type AuthStore = {
  method: AuthMethod;
  currentStep: AuthStep;
  email: string | null;
  isLoggingIn: boolean;
};

export enum AuthMethod {
  fullCredentials = "fullCredentials",
  credentials = "credentials",
  inviteCode = "inviteCode",
  sms = "sms",
}

export type AuthStepProps = {
  store?: AuthStore;
  visible?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
};

export type AuthStepActionsProps = {
  onSubmit?: () => Promise<void>;
  onShowInvite?: () => Promise<void>;
};

export interface AuthMethodLogic {
  checkData(): void;
  auth(): Promise<void>;
  goToNextStep(): Promise<unknown>;

  renderComponent(props: AuthStepProps): JSX.Element;
  renderActions(props: AuthStepActionsProps): JSX.Element;
}
