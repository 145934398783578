import { useCallback } from "react";

import { email as emailSchema } from "@gility/db/zod/authInputs";
import { Button, InputField } from "@gility/ui";

import { StepActionsContainer, StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthAction, AuthStep } from "./types";

export const EmailStep = () => {
  const store = useAuthStore();
  const form = store.form?.current;

  if (!form) {
    return null;
  }

  return (
    <StepContainer step={AuthStep.email}>
      <InputField
        label="Inserisci la tua e-mail"
        placeholder="E-mail"
        type="email"
        autoComplete="email"
        autoCapitalize="false"
        autoCorrect="false"
        spellCheck="false"
        {...form.register("email")}
      />
    </StepContainer>
  );
};

export const EmailStepActions = () => {
  const store = useAuthStore();
  const form = store.form?.current;

  const validateInput = useCallback(() => {
    if (!form) {
      return;
    }

    const email = form.getValues("email");
    const parsed = emailSchema.safeParse(email);
    if (parsed.error) {
      return form.setError("email", { type: "invalid", message: "E-mail non valida" });
    }

    store.setEmail(parsed.data);
    store.sendAction(AuthAction.email);
  }, [store, form]);

  if (!form) {
    return null;
  }

  return (
    <StepActionsContainer step={AuthStep.email}>
      <div>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            validateInput();
          }}
          loading={store.isLoggingIn}
          data-testid="next-button">
          Avanti
        </Button>
      </div>
      <Button
        onClick={() => {
          store.sendAction(AuthAction.invite);
        }}
        variant="link"
        color="primary">
        Hai ricevuto un codice invito?
      </Button>
    </StepActionsContainer>
  );
};
