import { useCallback } from "react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import { email as emailSchema } from "@gility/db/zod/authInputs";
import { Button, InputField, PasswordField, showToast } from "@gility/ui";

import { StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthAction, AuthStep } from "./types";

export const EmailPasswordStep = () => {
  const store = useAuthStore();
  const form = store.form?.current;

  if (!form) {
    return null;
  }

  return (
    <StepContainer step={AuthStep.emailPassword}>
      <div className="flex w-full flex-col gap-4">
        <InputField
          label="Inserisci la tua e-mail"
          placeholder="E-mail"
          type="email"
          autoComplete="email"
          autoCapitalize="false"
          autoCorrect="false"
          spellCheck="false"
          {...form.register("email")}
        />
        <PasswordField
          label="Password"
          placeholder=""
          autoComplete="current-password webauthn"
          autoCapitalize="false"
          autoCorrect="false"
          spellCheck="false"
          {...form.register("password")}
        />
        <div>
          <Button
            href={`/auth/reset${store.email ? `?email=${store.email}` : ""}`}
            variant="link"
            color="gray"
            className="mt-2"
            data-testid="reset-password-button">
            Ho dimenticato la password
          </Button>
        </div>
      </div>
    </StepContainer>
  );
};

export const EmailPasswordStepActions = () => {
  const store = useAuthStore();
  const router = useRouter();
  const form = store.form?.current;

  const passwordLogin = useCallback(async () => {
    if (!form) {
      return;
    }

    const email = form.getValues("email");
    const parsed = emailSchema.safeParse(email);
    if (parsed.error) {
      return form.setError("email", { type: "invalid", message: "E-mail non valida" });
    }

    store.setIsLoggingIn(true);

    await signIn("credentials", {
      email: form.getValues("email"),
      password: form.getValues("password"),
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        const callbackUrl = router.query.callbackUrl;
        void router.replace(
          callbackUrl && callbackUrl !== "/" ? callbackUrl.toString() : "/dashboard",
        );
      } else {
        if (res?.error) {
          showToast(
            "Errore durante l'autenticazione. Per favore ricontrolla i dati inseriti.",
            "error",
          );
        }
        store.setIsLoggingIn(false);
      }
    });
  }, [store, router, form]);

  return (
    <div className="mt-6 flex w-full flex-col gap-2 md:flex-row md:items-center md:gap-8">
      <div>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            void passwordLogin();
          }}
          loading={store.isLoggingIn}
          data-testid="next-button">
          Accedi
        </Button>
      </div>
      <Button
        onClick={() => {
          store.sendAction(AuthAction.invite);
        }}
        variant="link"
        color="primary">
        Hai ricevuto un codice invito?
      </Button>
    </div>
  );
};
