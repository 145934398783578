import { EmailPasswordStepActions } from "./EmailPasswordStep";
import { EmailPhoneStepActions } from "./EmailPhoneStep";
import { EmailStepActions } from "./EmailStep";
import { InviteCodeStepActions } from "./InviteCodeStep";
import { PasswordStepActions } from "./PasswordStep";
import { SmsStepActions } from "./SmsOTPStep";
import { useAuthStore } from "./store";
import { AuthStep } from "./types";

export const MultiStepActionsContainer = () => {
  const store = useAuthStore();

  switch (store.currentStep) {
    case AuthStep.emailOrPhone:
      return <EmailPhoneStepActions />;
    case AuthStep.email:
      return <EmailStepActions />;
    case AuthStep.password:
      return <PasswordStepActions />;
    case AuthStep.inviteCode:
      return <InviteCodeStepActions />;
    case AuthStep.smsOtp:
      return <SmsStepActions />;
    case AuthStep.emailPassword:
      return <EmailPasswordStepActions />;
  }

  return null;
};
