import { cn } from "@gility/lib";
import { Button, Heading } from "@gility/ui";
import { ArrowLeft } from "@gility/ui/components/icon";

import type { AuthStepProps } from "./types";
import { EmailPasswordStep } from "./EmailPasswordStep";
import { EmailPhoneStep } from "./EmailPhoneStep";
import { EmailStep } from "./EmailStep";
import { InviteCodeStep } from "./InviteCodeStep";
import { PasswordStep } from "./PasswordStep";
import { SmsStep } from "./SmsOTPStep";
import { useAuthStore } from "./store";
import { AuthAction, AuthStep } from "./types";

export type MultiStepContainerProps = AuthStepProps & {
  step?: JSX.Element;
};

export const MultiStepContainer = () => {
  const store = useAuthStore();

  const canGoBack = !!store.config.steps[store.currentStep]?.back;

  const allSteps = store.config.allSteps.map((step) => {
    switch (step) {
      case AuthStep.emailOrPhone:
        return <EmailPhoneStep key={step} />;
      case AuthStep.email:
        return <EmailStep key={step} />;
      case AuthStep.password:
        return <PasswordStep key={step} />;
      case AuthStep.emailPassword:
        return <EmailPasswordStep key={step} />;
      case AuthStep.inviteCode:
        return <InviteCodeStep key={step} />;
      case AuthStep.smsOtp:
        return <SmsStep key={step} />;
      default:
        return null;
    }
  });

  return (
    <div className="relative h-64">
      <div className="flex items-center justify-between gap-4">
        <Heading level={1} color="blue" noMargins>
          Accedi in piattaforma
        </Heading>
        {canGoBack && (
          <Button
            onClick={() => store.sendAction(AuthAction.back)}
            variant="link"
            color="gray"
            className="hidden gap-1 md:flex"
            icon={<ArrowLeft className="size-4" />}>
            Torna indietro
          </Button>
        )}
      </div>
      {store.email && (
        <div className="relative mt-2 inline-block md:flex md:flex-row md:items-center md:gap-4 lg:mt-6">
          <div className="absolute rounded border border-customGreen-500 bg-customGreen-100 px-4 py-1 text-blue">
            {store.email}
          </div>
        </div>
      )}
      <div
        className={cn(
          "absolute left-0 w-full",
          store.email ? "top-[100px] h-[calc(100%-100px)]" : "top-[46px] h-[calc(100%-46px)]",
        )}>
        {allSteps}
      </div>
    </div>
  );
};
