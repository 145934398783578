import type { JSX } from "react";

import { cn } from "@gility/lib";

export type CardProps = JSX.IntrinsicElements["div"] & {
  naked?: boolean;
  border?: boolean;
};

export const Card = ({
  children,
  className,
  naked = false,
  border = false,
  ...props
}: CardProps) => {
  return (
    <div
      className={cn(
        "rounded-lg bg-white",
        "duration-300 animate-in fade-in",
        naked ? "p-0" : "p-6 lg:p-8",
        border && "border border-brand/50",
        className,
      )}
      {...props}>
      {children}
    </div>
  );
};
