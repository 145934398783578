import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { MultiStepActionsContainer } from "@components/auth/MultiStepActionsContainer";
import { MultiStepContainer } from "@components/auth/MultiStepContainer";
import { useAuthStore } from "@components/auth/store";
import { AuthAction } from "@components/auth/types";
import Shell from "@components/Shell";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSession } from "next-auth/react";
import { FormProvider, useForm } from "react-hook-form";

import type { LoginInput } from "@gility/db/zod/authInputs";
import { loginInput } from "@gility/db/zod/authInputs";
import { AuthContainer, Button } from "@gility/ui";
import { ArrowLeft } from "@gility/ui/components/icon";

const Login = () => {
  const authStore = useAuthStore();
  const { data: session } = useSession();
  const router = useRouter();

  const methods = useForm<LoginInput>({
    mode: "onChange",
    resolver: zodResolver(loginInput),
  });
  const form = useRef(methods);

  useEffect(() => {
    if (!authStore.isReady) {
      if (!authStore.form) {
        authStore.setForm(form);
      }
      authStore.ready();
    }
  }, [authStore, methods]);

  const canGoBack = !!authStore.config.steps[authStore.currentStep]?.back;

  useEffect(() => {
    if (session?.user && !authStore.isLoggingIn) {
      void router.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <Shell isPublic withoutNav withoutFooter fullwidthPage>
      <AuthContainer
        callbackComponent={
          <Button
            onClick={() => {
              authStore.sendAction(AuthAction.back);
            }}
            variant="linkNoUnderline"
            color="lightGray"
            className="gap-1"
            icon={<ArrowLeft className="size-4" />}>
            Torna indietro
          </Button>
        }
        isCallbackVisible={canGoBack}>
        <div className="flex-1">
          <FormProvider {...methods}>
            <form
              name="loginForm"
              data-testid="login-form"
              className="flex flex-col justify-center"
              onSubmit={(evt) => {
                evt.preventDefault();
              }}>
              <div className="flex flex-1 flex-col justify-center">
                <MultiStepContainer />
              </div>

              <MultiStepActionsContainer />
            </form>
          </FormProvider>
        </div>
        <div className="flex h-36 items-end gap-1 text-sm text-blue">
          <span>
            La tua azienda non ha un account?{" "}
            <Button href="https://www.gility.it/demo" variant="linkStrong" target="_blank">
              Richiedi una demo gratuita
            </Button>
          </span>
        </div>
      </AuthContainer>
    </Shell>
  );
};

export default Login;
