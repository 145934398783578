import { useState } from "react";
import { useRouter } from "next/router";
import OTPInput from "@components/otp-input/OTPInput";

import { Button, showToast } from "@gility/ui";

import { api } from "~/utils/api";
import { StepActionsContainer, StepContainer } from "./StepContainer";
import { useAuthStore } from "./store";
import { AuthStep } from "./types";

export const InviteCodeStep = () => {
  const setOtpCode = useAuthStore((store) => store.setOtpCode);

  return (
    <StepContainer step={AuthStep.inviteCode}>
      <OTPInput
        title="Codice di verifica"
        length={8}
        onChange={(value) => setOtpCode(value)}
        className="mt-8"
        upcaseOnly
      />
    </StepContainer>
  );
};

export const InviteCodeStepActions = () => {
  const router = useRouter();
  const [isCheckingInviteOTP, setIsCheckingInviteOTP] = useState(false);
  const store = useAuthStore();
  const goBack = useAuthStore((store) => store.goBack);

  const { mutate: checkInviteOTPValidity } = api.invite.checkOTPCode.useMutation({
    onSuccess(inviteId) {
      void router.push(`/invites/${inviteId}/accept`);
    },
    onError: () => {
      showToast("Codice invito non valido", "error");
      setIsCheckingInviteOTP(false);
    },
  });

  const handleCheckOTP = () => {
    if (!store.otpCode || store.otpCode.length < 8) {
      return;
    }

    setIsCheckingInviteOTP(true);
    checkInviteOTPValidity({ inviteCode: store.otpCode });
  };

  return (
    <StepActionsContainer step={AuthStep.inviteCode}>
      <div>
        <Button
          type="submit"
          disabled={isCheckingInviteOTP}
          onClick={(e) => {
            e.preventDefault();
            handleCheckOTP();
          }}>
          Registrati
        </Button>
      </div>
      <Button onClick={goBack} variant="link">
        Sei già registrato? Accedi
      </Button>
    </StepActionsContainer>
  );
};
