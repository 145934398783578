import { type MutableRefObject } from "react";
import { type UseFormReturn } from "react-hook-form";
import { create } from "zustand";

import type { AuthConfig, AuthMethod, AuthStep } from "./types";
import { AuthAction, twoStepEmailPasswordConfig } from "./types";

// TODO: Cambiare il valore di config per cambiare la configurazione dell'autenticazione multistep
const config = twoStepEmailPasswordConfig;

interface RawAuthState {
  currentStep: AuthStep;
  isLoggingIn: boolean;
  email: string | null;
  password: string | null;
  otpCode: string | null;
  isReady: boolean;
}

type FormType = MutableRefObject<
  UseFormReturn<
    {
      email: string;
      emailOrPhone: string;
      password: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    undefined
  >
>;

interface AuthState extends RawAuthState {
  config: AuthConfig;
  form?: FormType;
  goBack: () => void;
  sendAction: (action: AuthAction) => void;
  setMethod: (method: AuthMethod) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setOtpCode: (otpCode: string) => void;
  setIsLoggingIn: (isLoggingIn: boolean) => void;
  setForm: (form: FormType) => void;
  ready: () => void;
}

const cleanState: RawAuthState = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  currentStep: config.allSteps[0]!,
  isLoggingIn: false,
  email: null,
  password: null,
  otpCode: null,
  isReady: false,
};

export const useAuthStore = create<AuthState>()((set) => ({
  ...cleanState,
  config,
  goBack: () =>
    set((state) => ({
      ...state,
      ...cleanState,
      isReady: true,
    })),
  setForm: (form) => set((state) => ({ ...state, form })),
  setEmail: (email) => set((state) => ({ ...state, email })),
  setPassword: (password) => set((state) => ({ ...state, password })),
  setOtpCode: (otpCode) => set((state) => ({ ...state, otpCode })),
  sendAction: (action: AuthAction) => {
    return set((state) => {
      const nextStep = state.config.steps[state.currentStep]?.[action];
      if (nextStep) {
        if (action === AuthAction.back) {
          return { ...state, ...cleanState, currentStep: nextStep };
        } else {
          return { ...state, currentStep: nextStep };
        }
      }
      return state;
    });
  },
  setMethod: (method: AuthMethod) => set((state) => ({ ...state, method })),
  setIsLoggingIn: (isLoggingIn) => set((state) => ({ ...state, isLoggingIn })),
  ready: () => set((state) => ({ ...state, isReady: true })),
}));
