import type { JSX } from "react";
import React from "react";

import { cn } from "@gility/lib";

import { useAuthStore } from "./store";
import { type AuthStep } from "./types";

export type StepContainerProps = JSX.IntrinsicElements["div"] & {
  step: AuthStep;
};

export const StepContainer = ({ step, children, className, ...other }: StepContainerProps) => {
  const currentStep = useAuthStore((store) => store.currentStep);
  const visible = currentStep === step;
  return (
    <div
      className={cn(
        "absolute flex h-full w-full flex-row items-center bg-white",
        visible ? "z-10" : "-z-20",
        className,
      )}
      {...other}>
      <div className="w-full">{children}</div>
    </div>
  );
};

export type StepActionsContainerProps = JSX.IntrinsicElements["div"] & {
  step: AuthStep;
};
export const StepActionsContainer = ({
  step,
  children,
  className,
  ...other
}: StepActionsContainerProps) => {
  const currentStep = useAuthStore((store) => store.currentStep);
  const visible = currentStep === step;
  return (
    <div
      className={cn(
        "mt-6 w-full flex-col gap-2 md:flex-row md:items-center md:gap-8",
        visible ? "flex" : "hidden",
        className,
      )}
      {...other}>
      {children}
    </div>
  );
};
